import RentalIcon from "assets/img/plugoboxImg.png";
import PickUpIcon from "assets/img/plugoRental.png";
import UpTimeIcon from "assets/img/UpTimeIcon.png";
import graphIcon from "assets/img/graphIcon.png";
import CustomerDefaultIcon from "assets/img/premiumUserIcon.png";
import Money from "assets/img/money.png";
import RevenueReportIcon from "assets/img/financial_report.png";
import Invoice from "assets/img/paper.png";
import RentalHistory from "assets/img/rentalHistoryIcon.png";

export default (
  state = {
    permissions_europe: [
      {
        Security: "VIEW_RENTALS",
        Label: "Rental Report",
        SRC: RentalIcon
      },
      {
        Security: "VIEW_STORE_USER",
        Label: "Store User Report",
        SRC: PickUpIcon
      },
      {
        Security: "VIEW_BOX_STATUS",
        Label: "Box Status",
        SRC: UpTimeIcon
      },
      {
        Security: "VIEW_INVENTORY",
        Label: "Box Inventory",
        SRC: graphIcon
      },
      {
        Security: "VIEW_DEFAULT_CUSTOMER",
        Label: "Customer Default",
        SRC: CustomerDefaultIcon
      },
      {
        Security: "VIEW_INVOICE",
        Label: "Invoice",
        SRC: Invoice
      },
      {
        Security: "VIEW_RENTAL_HISTORY",
        Label: "Rental History",
        SRC: RentalHistory
      }
    ],
    permissions_india: [
      {
        Security: "VIEW_RENTALS",
        Label: "Rental Report",
        SRC: RentalIcon
      },
      {
        Security: "VIEW_STORE_USER",
        Label: "Store User Report",
        SRC: PickUpIcon
      },
      {
        Security: "VIEW_BOX_STATUS",
        Label: "Box Status",
        SRC: UpTimeIcon
      },
      {
        Security: "VIEW_INVENTORY",
        Label: "Box Inventory",
        SRC: graphIcon
      },
      {
        Security: "VIEW_DEFAULT_CUSTOMER",
        Label: "Customer Default",
        SRC: CustomerDefaultIcon
      },
      {
        Security: "VIEW_REVENUE",
        Label: "Revenue",
        SRC: RevenueReportIcon
      },
      {
        Security: "VIEW_RENTALS",
        Label: "Merchant Revenue",
        SRC: Money
      }
      //   ,
      //   {
      //     Security:'VIEW_RENTALS',
      //     Label:'Sales lead',
      //     SRC:increaseIcon
      //   }
    ]
  },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
