import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DownloadIcon from 'assets/img/DownloadIcon.png';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import Axios from 'axios';
import { API_CONFIG } from "../../../config/config.jsx";

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "0px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    form: {
        marginLeft: "40%"
    },
    downloadStyle: {
        width: 20,
        height: 20,
        marginLeft: "79%",
        cursor: "pointer",
        marginBottom: "2px"
    },
});
const createWorkbook = (records) => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2019, 12, 19)
    };

    wb.SheetNames.push("Customer Default Table");
    const data = records.data.map(obj => [obj.storePin, obj.firstName, obj.lastName, obj.mobileNo, obj.rentalStartDate, obj.walletBalance, obj.userType, obj.daysElapsed]);
    const ws_data = [['Store PIN', 'First Name', 'Last Name', 'Mobile No', 'Rental Start Date', 'Wallet Balance', 'User Type', 'Days Elapsed'], ...data];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Customer Default Table"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    return wbout;
}
const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

class CustomerDefaultReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            city: '',
            customerDefaultData: []
        };
    }
    handleExcel = () => {
        const records = this.reactTable.getResolvedState();
        console.log(records);
        saveAs(new Blob([s2ab(createWorkbook(records))], { type: "application/octet-stream" }), 'test.xlsx');
    }
    componentDidMount = () => {
        this.fetchData()
    }
    fetchData = () => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');


        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/customerdefaults/${this.props.city}/${userId}`,
        )
            .then(res => {
                this.setState({
                    customerDefaultData: res.data
                });

            });
    }
    componentWillReceiveProps = (nextProps) => {
        const token = sessionStorage.getItem('plugoAuthKey');
        const userId = sessionStorage.getItem('plugoUserId');

        if (this.props.city === nextProps.city) {
            return;
        }

        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/customerdefaults/${nextProps.city}/${userId}`)
            .then(res => {
                this.setState({
                    customerDefaultData: res.data
                });
            });
    }
    render() {
        const { classes } = this.props;
        let currentNumber = sessionStorage.getItem("plugoUserMobileNo");
        let filterCustomerDefaultData =[];
        if(currentNumber.includes("+31")){
            filterCustomerDefaultData = this.state.customerDefaultData.filter((e)=> e.mobile.includes("+31"));
        }else{
            filterCustomerDefaultData = this.state.customerDefaultData;
        }
        let customerDefaultData = filterCustomerDefaultData.map((row, index) => {
            return {
                storePin: row.storePincode,
                firstName: row.firstName,
                lastName: row.lastName,
                mobileNo: row.mobile,
                rentalStartDate: row.rentalStartDate,
                walletBalance: row.walletBalance,
                userType: row.userType,
                daysElapsed: row.dayElapsed,
                email: row.email,
                referralCode: row.referralCode
            }
        });
        return (
            <div>
                <Card>
                    <CardHeader color="success" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h4 className={classes.cardTitleWhite}> Customer Default Report</h4>
                            <img onClick={this.handleExcel} title="Download as Excel" className={classes.downloadStyle} src={DownloadIcon}></img>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {process.env.REACT_APP_ACTIVE_REGION !== "europe" ?
                            <ReactTable
                                data={customerDefaultData}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}

                                columns={[

                                    {
                                        headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Store PIN",
                                        id: "storePin",
                                        accessor: d => d.storePin,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["storePin"] }),
                                        filterAll: true
                                    },

                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "First Name",
                                        id: "firstName",
                                        accessor: d => d.firstName,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["firstName"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Last Name",
                                        id: "lastName",
                                        accessor: d => d.lastName,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["lastName"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Mobile No",
                                        id: "mobileNo",
                                        accessor: d => d.mobileNo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["mobileNo"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Rental Start Date",
                                        id: "rentalStartDate",
                                        accessor: d => d.rentalStartDate,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["rentalStartDate"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Wallet Balance",
                                        id: "walletBalance",
                                        accessor: d => d.walletBalance,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["walletBalance"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "User Type",
                                        accessor: "userType",
                                        filterMethod: (filter, row) => {
                                            const value = filter.value
                                            const userType = row["userType"].toLowerCase()
                                            if (value === "all") {
                                                return true;
                                            }

                                            if (value === "standard") {
                                                return userType === "standard"
                                            }

                                            if (value === "annual") {
                                                return userType === "annual"
                                            }

                                            if (value === "quaterly") {
                                                return userType === "quaterly"
                                            }

                                            if (value === "monthly") {
                                                return userType === "monthly"
                                            }

                                        },
                                        Filter: ({ filter, onChange }) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="all">all</option>
                                                <option value="standard">Standard</option>
                                                <option value="annual">Annual</option>
                                                <option value="quaterly">Quaterly</option>
                                                <option value="monthly">Monthly</option>
                                            </select>
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Days Elapsed",
                                        id: "daysElapsed",
                                        accessor: d => d.daysElapsed,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["daysElapsed"] }),
                                        filterAll: true
                                    }
                                ]}
                                // defaultPageSize={5}
                                className="-striped -highlight"
                                ref={(r) => this.reactTable = r}
                            />
                            :
                            <ReactTable
                                data={customerDefaultData}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}

                                columns={[
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "First Name",
                                        id: "firstName",
                                        accessor: d => d.firstName,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["firstName"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Last Name",
                                        id: "lastName",
                                        accessor: d => d.lastName,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["lastName"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Mobile No",
                                        id: "mobileNo",
                                        accessor: d => d.mobileNo,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["mobileNo"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Email",
                                        id: "email",
                                        accessor: d => d.email,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["email"] }),
                                        filterAll: true
                                    },
                                    {
                                        headerStyle: { fontSize: '16px', paddingTop: '10px' },
                                        style: { textAlign: "center" },
                                        Header: "Referral Code",
                                        id: "referralCode",
                                        accessor: d => d.referralCode,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["referralCode"] }),
                                        filterAll: true
                                    }
                                ]}
                                // defaultPageSize={5}
                                className="-striped -highlight"
                                ref={(r) => this.reactTable = r}
                            />
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }

}
CustomerDefaultReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ city: state.city.city, pincodes: state.city.pincodes })
export default connect(mapStateToProps, null, null, { pure: false })(withStyles(styles)(CustomerDefaultReport))
