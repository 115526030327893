import React from "react";
import { connect } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Axios from 'axios';
import { API_CONFIG } from "../../config/config.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
// core components
import { logout } from '../../actions/auth';
import { changeCity } from '../../actions/city';
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { User } from "../../User";


class HeaderLinks extends React.Component {
  state = {
    cityWithPins: [],
    city: '',
    pincodes: [],
    open: false,
    defaultHydCity: '',
    hydData: '',
    indiaflag:true
  };
  componentDidMount() {

    const token = sessionStorage.getItem('plugoAuthKey');
      const userId = sessionStorage.getItem('plugoUserId');
  
    if(process.env.REACT_APP_ACTIVE_REGION === "europe" )
    {
      Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/stores/cities/${userId}`)
      .then(res => {
        if(res.data.length === 0 ){
          this.setState({
            cityWithPins: []
          });
        }
        else{
          this.setState({
            cityWithPins: res.data,
            city: res.data[0].City
          });
          this.props.changeCity(res.data[0].City, res.data[0].Pincodes);
        }
      });
    }
    else
    {
      
      Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/stores/cities/${userId}`)
        .then(res => {
          if(res.data.length === 0 ){
            this.setState({
              cityWithPins: []
            });
          }
          else{
            this.setState({
              cityWithPins: res.data,
              city: res.data[0].City
            });
            this.props.changeCity(res.data[0].City, res.data[0].Pincodes);
          }
        });
    }
    
  }
  handleChange = event => {

    this.setState({
      city: event.target.value
    });
    let selectedCity = this.state.cityWithPins.find(selectCity => {
      return selectCity.City === event.target.value
    });
    console.log(selectedCity);
    this.props.changeCity(event.target.value, selectedCity.Pincodes);


  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    let filterCity = [];
    if(!User.isSuperAdmin()){
      filterCity = this.state.cityWithPins.filter(e => e.City !== "All")
    }else{
      filterCity = this.state.cityWithPins
    }
    let menuCities = filterCity.map(menuCity => (
      <MenuItem key={menuCity.City} value={menuCity.City}>{menuCity.City}</MenuItem>
    ));
    console.log("userr ", User.isSuperAdmin(), this.state.cityWithPins)
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "10px" }}>
          {/* {this.state.indiaflag ? ( */}
                <form className={classes.form} autoComplete="on">
                <FormControl style={{ marginRight: "35px", minWidth: 120 }}>
                  <InputLabel htmlFor="demo-controlled-open-select">City</InputLabel>
                  <Select
                    open={this.state.open}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    value={this.state.city}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'city',
                      id: 'demo-controlled-open-select',
                    }}
                  >
                    {menuCities}
                  </Select>
                </FormControl>
              </form>
          <div className={classes.searchWrapper}>
            <Button
              onClick={() => this.props.logout()}
              variant="contained"
              color="inherit"
              className={classes.button}>
              Logout
         </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { logout, changeCity }, null, { pure: false })(withStyles(headerLinksStyle)(HeaderLinks))
