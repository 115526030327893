import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from "axios";
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Popover from "@material-ui/core/Popover";
import { Input, Button, InputGroup } from "reactstrap";
import moment from "moment";
import pdficon from "./../../../assets/img/pdf.png";
import DownloadIcon from "assets/img/DownloadIcon.png";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    },
  },
  downloadStyle: {
    width: 20,
    height: 20,
    marginLeft: "85%",
    cursor: "pointer",
    marginBottom: "2px"
  }
});

const createWorkbook = records => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "SheetJS Tutorial",
    Subject: "Test",
    Author: "Red Stapler",
    CreatedDate: new Date(2019, 12, 19)
  };

  wb.SheetNames.push("Rental History Report");
  const data = records.data.map(obj => [
    
    obj.rentType,
    obj.mobile,
    obj.userName,
    obj.rentStoreName,
    obj.rentStorePincode,
    obj.returnStoreName,
    obj.returnStorePincode,
    obj.startTime,
    obj.endTime,
    obj.paymentStatus,
    obj.couponCode,
    obj.rentStoreCountry,
    obj.returnStoreCountry,
    obj.powerbankId,
    obj.rentalHours,
    obj.charge,
    obj.paymentBy,
    obj.paymentId,
    obj.invoiceNumber,
    obj.buid,
  ]);

  
  const ws_data = [
    [
      "Rent Type",
      "Mobile",
      "User Name",
      "Rent Store",
      "Rent Store Pincode",
      "Return Store",
      "Return Store Pincode",
      "Start Time",
      "End Time",
      "Payment Status",
      "Coupon Code",
      "Rent Store Country",
      "Return Store Country",
      "Powerbank",
      "Rental Hours",
      "Charge",
      "Payment By",
      "Payment ID",
      "Invoice Number",
      "BUID"
      
    ],
    ...data
  ];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Rental History Report"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  return wbout;
};
const s2ab = s => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};
class RentalHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalHistoryData: [],
      // text: ' To ',
      open: false,
      startDate: moment()
        .subtract(30, "days")
        .toDate(),
      endDate: new Date(),
      showDefaultReport: false,
      openPopOver: false
    };
    this.reactTable = React.createRef();
  }
  handleSelect = ranges => {
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
      // dateValue: moment(ranges.selection.startDate).format('MMM-DD-YYYY') + this.state.text + moment(ranges.selection.endDate).format('MMM-DD-YYYY')
    });
  };
  handleClick = event => {
    this.setState({
      open: !this.state.open,
      openPopOver: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      openPopOver: false
    });
  };
  handleApply = () => {
    this.setState(
      {
        openPopOver: false
      },
      () => {
        this.fetchData();
      }
    );
    // const startDate = moment(this.state.startDate).format('DD-MM-YYYY');
    // const endDate = moment(this.state.endDate).format('DD-MM-YYYY');
    // this.props.fetchStandardReportData(startDate, endDate, true);
  };
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");
    const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.endDate).format("YYYY-MM-DD");

    Axios.get(
      `https://dartheu.plugo.io/api/v1/darth/box-report/rentals/cc18fc84-61e8-46f6-ab26-0dbfa51af38b?startDate=${startDate}&endDate=${endDate}`,{
        headers: {
          "Authorization": `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJQTFVHTyBFdXJvcGUiLCJpYXQiOjE3MDA3MjUxNjB9.v0_Vvk6vtU7_DeBl-cv0Chl_T9HFuMbinWLUEj3oE56ZzGF34hSpty9BSGpaT0ROQ5gFx6rHz2t2P2CJ2TP-XA`
      }
      }
    ).then(res => {
      this.setState({ 
        rentalHistoryData: res.data.reverse()

      });
    });
  };
  currentfile = val => {
    let link = "https://" + val;
    var elem = document.createElement("a");
    elem.setAttribute("href", link);
    elem.setAttribute("download", "download");
    elem.click();
  };

   handleExcel = () => {
    const records = this.reactTable.getResolvedState();
    saveAs(
      new Blob([s2ab(createWorkbook(records))], {
        type: "application/octet-stream"
      }),
      "test.xlsx"
    );
  };
  // }
  render() {
    const { classes } = this.props;
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: "selection"
    };
    let currentNumber = sessionStorage.getItem("plugoUserMobileNo");
        let filterRentalDefaultData =[];
        if(currentNumber.includes("+31")){
          filterRentalDefaultData = this.state.rentalHistoryData.filter((e)=> e.mobile.includes("+31"));
        }else{
          filterRentalDefaultData = this.state.rentalHistoryData;
        }
    let rentalHistoryData = filterRentalDefaultData.map((row, index) => {
      return {
        mobile: row.mobile,
        userName:row.userName,
        rentStoreName: row.rentStoreName,
        rentStorePincode: row.rentStorePincode,
        returnStoreName: row.returnStoreName,
        returnStorePincode: row.returnStorePincode,
        startTime: moment(row.rentStartTime).format("Y-MM-DD HH:mm:ss"),
        endTime: moment(row.rentEndTime).format("Y-MM-DD HH:mm:ss"),
        couponCode: row.couponCode,
        paymentStatus: row.paymentStatus,
        invoiceNumber: row.invoiceNumber,
        invoiceLink: row.invoiceLink,
        rentStoreCountry: row.rentStoreCountry,
        returnStoreCountry: row.returnStoreCountry,
        powerbankId: row.powerbankId,
        rentalHours: row.rentalHours,
        charge: row.charge,
        paymentId:row.paymentId,
        paymentBy: row.paymentBy,
        rentType:row.rentType,
        buid:row.buid
      };
    });


    return (
      <div>
        <Card>
          <CardHeader color="success">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h4 className={classes.cardTitleWhite}>Rental History</h4>
            <img
                onClick={this.handleExcel}
                title="Download as Excel"
                className={classes.downloadStyle}
                src={DownloadIcon}
              />
              </div>
          </CardHeader>
          <CardBody>
            <div style={{ marginBottom: "10px" }}>
              <InputGroup style={{ width: "50%" }}>
                <Input
                  value={
                    moment(this.state.startDate).format("DD.MM.YYYY") +
                    " To " +
                    moment(this.state.endDate).format("DD.MM.YYYY")
                  }
                  onClick={this.handleClick}
                  autoComplete="off"
                  // placeholder="Select Date Range"
                />
              </InputGroup>
              <Popover
                open={this.state.openPopOver}
                // anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                <div>
                  <DateRange
                    editableDateInputs={true}
                    onChange={this.handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    minDate={moment()
                      .subtract(2, "months")
                      .startOf("month")
                      .toDate()}
                    direction="horizontal"
                    ranges={[selectionRange]}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", margin: "2px 10px" }}
                    onClick={this.handleApply}
                  >
                    Apply
                  </Button>
                </div>
              </Popover>
            </div>
            <ReactTable
            ref={r => (this.reactTable = r)}
              data={rentalHistoryData}
              // onFetchData={this.fetchData}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Rent Type",
                  id: "rentType",
                  accessor: d => d.rentType,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["rentType"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Mobile",
                  id: "mobile",
                  accessor: d => d.mobile,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["mobile"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "User Name",
                  id: "userName",
                  accessor: d => d.userName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["userName"] }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Rent Store Name",
                  id: "rentStoreName",
                  accessor: d => d.rentStoreName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["rentStoreName"]
                    }),
                  filterAll: true
                },

                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Rent Store Pincode",
                  id: "rentStorePincode",
                  accessor: d => d.rentStorePincode,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["rentStorePincode"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Return Store Name",
                  id: "returnStoreName",
                  accessor: d => d.returnStoreName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["returnStoreName"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Return Store Pincode",
                  accessor: "returnStorePincode",
                  id: "returnStorePincode",
                  accessor: d => d.returnStorePincode,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["returnStorePincode"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 11,
                  Header: "Start Time",
                  id: "startTime",
                  accessor: d => d.startTime,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["startTime"] }),
                  filterAll: true,
                  sortMethod: (a, b) => {
                    const a1 = moment(a, "LLL");
                    const b1 = moment(b, "LLL");
                    if (a1.isBefore(b1)) return 1;
                    else if (a1.isAfter(b1)) return -1;
                    else return 0;
                  }
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 11,
                  Header: "End Time",
                  id: "endTime",
                  accessor: d => d.endTime,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["endTime"] }),
                  filterAll: true,
                  sortMethod: (a, b) => {
                    const a1 = moment(a, "LLL");
                    const b1 = moment(b, "LLL");
                    if (a1.isBefore(b1)) return 1;
                    else if (a1.isAfter(b1)) return -1;
                    else return 0;
                  }
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Coupon Code",
                  accessor: "couponCode",
                  id: "couponCode",
                  accessor: d => d.couponCode,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["couponCode"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Payment Status",
                  accessor: "paymentStatus",
                  id: "paymentStatus",
                  accessor: d => d.paymentStatus,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["paymentStatus"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Invoice Number",
                  accessor: "invoiceNumber",
                  id: "invoiceNumber",
                  accessor: d => d.invoiceNumber,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["invoiceNumber"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Rent Store Country",
                  accessor: "rentStoreCountry",
                  id: "rentStoreCountry",
                  accessor: d => d.rentStoreCountry,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["rentStoreCountry"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Return Store Country",
                  accessor: "returnStoreCountry",
                  id: "returnStoreCountry",
                  accessor: d => d.returnStoreCountry,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["returnStoreCountry"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Power Bank Id",
                  accessor: "powerbankId",
                  id: "powerbankId",
                  accessor: d => d.powerbankId,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["powerbankId"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Rental Hours",
                  accessor: "rentalHours",
                  id: "rentalHours",
                  accessor: d => d.rentalHours,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["rentalHours"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Charge",
                  accessor: "charge",
                  id: "charge",
                  accessor: d => d.charge,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["charge"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Payment By",
                  accessor: "paymentBy",
                  id: "paymentBy",
                  accessor: d => d.paymentBy,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["paymentBy"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Payment ID",
                  id: "paymenyId",
                  accessor: d => d.paymentId,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["paymentId"] }),
                  filterAll: true
                },{
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "buid",
                  id: "buid",
                  accessor: d => d.buid,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["buid"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Invoice Link",
                  accessor: "invoiceLink",
                  id: "invoiceLink",
                  accessor: d =>
                    d.invoiceLink !== null ? (
                      <img
                        src={pdficon}
                        style={{ width: "30px", height: "30px" }}
                        onClick={() => this.currentfile(d.invoiceLink)}
                      />
                    ) : (
                      ""
                    ),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["invoiceLink"]
                    }),
                  filterAll: true
                }
              ]}
              // defaultPageSize={10}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
RentalHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RentalHistory);
