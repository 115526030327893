import React, { Component } from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { API_CONFIG } from "./../../../config/config.jsx";
import Axios from "axios";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import { DateRange } from "react-date-range";
import pdficon from "./../../../assets/img/pdf.png";
import { Input, Button, InputGroup } from "reactstrap";
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

class InvoiceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allinvoice: [],
      mobile: "",
      name: "",
      startDate: moment()
        .subtract(30, "days")
        .toDate(),
      endDate: new Date(),
      openPopOver: false,
      open: false,
      disabled: true,
      bulkUrl: '',
      isLoading: false
    };
  }

  currentfile = val => {
    let link = "https://" + val;
    var elem = document.createElement("a");
    elem.setAttribute("href", link);
    elem.setAttribute("download", "download");
    elem.click();
  };
  handleSelect = ranges => {
    console.log(ranges.selection);
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
      // dateValue: moment(ranges.selection.startDate).format('MMM-DD-YYYY') + this.state.text + moment(ranges.selection.endDate).format('MMM-DD-YYYY')
    });
  };

  handleClick = event => {
    console.log(event);
    this.setState({
      open: !this.state.open,
      openPopOver: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      openPopOver: false
    });
  };

  fetchData = ()=>{
    const token = sessionStorage.getItem("plugoAuthKey");
     const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
    Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/rentals/invoices/zip?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        this.setState({ bulkUrl: res.data, isLoading: false, disabled: false})
      })
      .catch(err => {
        console.log(err);
        this.setState({
          allinvoice: []
        });
      });
  }
  handleApply = () => {
    this.setState(
      {
        openPopOver: false,
        isLoading: true
      }
        ,
        () => {
          this.fetchData();
        }
    );
    // const startDate = moment(this.state.startDate).format('DD-MM-YYYY');
    // const endDate = moment(this.state.endDate).format('DD-MM-YYYY');
    // this.props.fetchStandardReportData(startDate, endDate, true);
  };

  componentDidMount() {
    const token = sessionStorage.getItem("plugoAuthKey");
    Axios.get(`${API_CONFIG.HOST}/invoice/all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        this.setState({
          allinvoice: res.data
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          allinvoice: []
        });
      });
  }

  render() {
    const { classes } = this.props;
    let invoiceData = this.state.allinvoice.map((elem, index) => {
      let created_date = elem.createdAt.split("T")[0];
      return {
        invoice: elem.parent,
        mobile: elem.mobile,
        datecreated: created_date,
        action: (
          <img
            src={pdficon}
            style={{ width: "30px", height: "30px" }}
            onClick={() => this.currentfile(elem.url)}
          />
        )
      };
    });
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: "selection"
    };
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Invoice Report</h4>
          </CardHeader>
          <div
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              marginLeft: "25px",
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <InputGroup style={{ width: "50%" }}>
              <Input
                value={
                  moment(this.state.startDate).format("DD.MM.YYYY") +
                  " To " +
                  moment(this.state.endDate).format("DD.MM.YYYY")
                }
                onClick={this.handleClick}
                autoComplete="off"
                // placeholder="Select Date Range"
              />
            </InputGroup>
            <Popover
              open={this.state.openPopOver}
              // anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div>
                <DateRange
                  editableDateInputs={true}
                  onChange={this.handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  minDate={moment()
                    .subtract(2, "months")
                    .startOf("month")
                    .toDate()}
                  direction="horizontal"
                  ranges={[selectionRange]}
                /> 
                
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right", margin: "2px 10px" }}
                  onClick={this.handleApply}
                >
                  Generate Link
                </Button>
              </div>
            </Popover>
            {this.state.isLoading ?
                 <CircularProgress /> : ''
                }
          </div>
          <div
            style={{
              marginBottom: "20px",
              marginLeft: "25px"
            }}
          >
            <Button disabled={this.state.disabled} onClick={()=>this.currentfile(this.state.bulkUrl)} color={this.state.disabled ? "secondary" : "info"}>Download</Button>
          </div>
        </Card>
      </div>
    );
  }
}

InvoiceReport.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InvoiceReport);
