import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Axios from "axios";
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import DownloadIcon from "assets/img/DownloadIcon.png";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import matchSorter from "match-sorter";
import Warning from "assets/img/warn.png";
import { connect } from "react-redux";

const styles = theme => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  title: {
    fontSize: 14
  },
  downloadStyle: {
    width: 20,
    height: 20,
    marginLeft: "85%",
    cursor: "pointer",
    marginBottom: "2px"
  }
});
const createWorkbook = records => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "SheetJS Tutorial",
    Subject: "Test",
    Author: "Red Stapler",
    CreatedDate: new Date(2019, 12, 19)
  };

  wb.SheetNames.push("Box Status Report");
  const data = records.data.map(obj => [
    obj.storeName,
    obj.sixCharId,
    obj.deviceId,
    obj.city,
    obj.pincode,
    obj.status,
    obj.unHealthyPowerbankSlots,
    obj.availablePowerBanks,
    obj.offlineSince
  ]);
  const ws_data = [
    [
      "Store Name",
      "SixChar Id",
      "Device Id",
      "City",
      "Pincode",
      "Status",
      "UnHealthy Powerbank Slots",
      "Available Power Banks",
      "Offline Since"
    ],
    ...data
  ];
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["Box Status Report"] = ws;
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  return wbout;
};
const s2ab = s => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

class BoxStatusReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxData: [],
    };
    this.reactTable = React.createRef();
  }

  componentDidMount = () => {

    const token = sessionStorage.getItem("plugoAuthKey");
    const userId = sessionStorage.getItem("plugoUserId");
    Axios.get(
      `${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/powerbankandbox/report/${this.props.city}`
    ).then(res => {
      let boxDataApi = [];
      res.data.map(row => {
        if (row.offlineSince == "Online" && row.status == "OFFLINE") {
          let boxinfo = {
            availablePowerBanks: 0,
            city: row.city,
            deviceId: row.deviceId,
            offlineSince: (
              <img
                src={Warning}
                width="20px"
                height="20px"
                title={
                  "Box is online and seems like it is marked as offline manually. Please contact admin for more details."
                }
              />
            ),
            pincode: row.pincode,
            sixCharId: row.sixCharId,
            status: row.status,
            storeName: row.storeName
          };
          boxDataApi.push(boxinfo);
        } else {
          boxDataApi.push(row);
        }
      });
      this.setState({
        boxData: boxDataApi
      });
    });
  };

  handleExcel = () => {
    const records = this.reactTable.getResolvedState();
    saveAs(
      new Blob([s2ab(createWorkbook(records))], {
        type: "application/octet-stream"
      }),
      "test.xlsx"
    );
  };

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          color:
            rowInfo.row.status === "OFFLINE"
              ? "orange"
              : rowInfo.row.status === "Low Stock"
              ? "red"
              : "gray"
          //   color: 'white'
        }
      };
    }
    return {};
  };
  render() {
    const { classes } = this.props;
    let boxStatusData = this.state.boxData.map((row, index) => {
      return {
        storeName: row.storeName,
        sixCharId: row.sixCharId,
        deviceId: row.deviceId,
        city: row.city,
        pincode: row.pincode,
        status: row.status,
        unHealthyPowerbankSlots: row.unHealthyPowerbankSlots,
        availablePowerBanks: row.availablePowerBanks,
        offlineSince: row.offlineSince
      };
    });
    return (
      <div>
        <Card>
          <CardHeader color="success">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4 className={classes.cardTitleWhite}>Box Status</h4>
              <img
                onClick={this.handleExcel}
                title="Download as Excel"
                className={classes.downloadStyle}
                src={DownloadIcon}
              />
            </div>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={boxStatusData}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Store Name",
                  id: "storeName",
                  accessor: d => d.storeName,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["storeName"] }),
                  filterAll: true
                },
                {
                  headerStyle: {
                    fontSize: "16px",
                    height: "40px",
                    paddingTop: "10px"
                  },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "SixChar Id",
                  id: "sixCharId",
                  accessor: d => d.sixCharId,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["sixCharId"] }),
                  filterAll: true
                },

                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Device Id",
                  id: "deviceId",
                  accessor: d => d.deviceId,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["deviceId"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "City",
                  id: "city",
                  accessor: d => d.city,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["city"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Pincode",
                  accessor: "pincode",
                  id: "pincode",
                  accessor: d => d.pincode,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["pincode"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Status",
                  id: "status",
                  titile: "status",
                  accessor: d => d.status,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "UnHealthy Powerbank Slots",
                  id: "unHealthyPowerbankSlots",
                  accessor: d => d.unHealthyPowerbankSlots,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["unHealthyPowerbankSlots"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Available Power Banks",
                  accessor: "availablePowerBanks",
                  id: "availablePowerBanks",
                  accessor: d => d.availablePowerBanks,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ["availablePowerBanks"]
                    }),
                  filterAll: true
                },
                {
                  headerStyle: { fontSize: "16px", paddingTop: "10px" },
                  style: { textAlign: "center" },
                  minWidth: 10,
                  Header: "Offline Since",
                  accessor: "offlineSince",
                  id: "offlineSince",
                  accessor: d => d.offlineSince,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["offlineSince"] }),
                  filterAll: true
                }
              ]}
              className="-striped -highlight"
              getTrProps={this.getTrProps}
              ref={r => (this.reactTable = r)}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
BoxStatusReport.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  city: state.city.city,
});
export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(withStyles(styles)(BoxStatusReport));