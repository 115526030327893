import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Axios from 'axios';
import { API_CONFIG } from "../../../config/config.jsx";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import { connect } from 'react-redux';

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});

class IncomeReportByStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storeIncomeData: [],
            pickUpData: [],
            showTable: false,
            userData: [],
        };
    }
    fetchData = () => {

        const userId = sessionStorage.getItem('plugoUserId');
        Axios.get(`${API_CONFIG.PLUGO_REPORTS_API_HOST}/report/stores/income/${this.props.city}`
        )
            .then(res => {
                this.setState({
                    pickUpData: res.data
                });

            });
    }

    render() {
        const { classes } = this.props;
        const onRowClick = (state, rowInfo, column, instance) => {
            return {
                onClick: e => {
                    // this.handleStore(rowInfo)
                    // this.setState({
                    //     showTable: true
                    // });
                    console.log('it produced this event:', state)
                    console.log('It was in this column:', column)
                    console.log('It was in this row:', rowInfo.original)
                    console.log('It was in this table instance:', instance)
                    const storeId = rowInfo.original.storeId

                }
            }
        }
        let pickUpData = this.state.pickUpData.map((row, index) => {
            return {
                pincode: row.pincode,
                storeName: row.storeName,
                dailyTotalUser: row.dailyTotalUsers,
                dailyStandardUser: row.dailyStandardUsers,
                dailyPremiumUser: row.dailyPremiumUsers,
                weeklyTotalUser: row.weeklyTotalUsers,
                weeklyStandardUser: row.weeklyStandardUsers,
                weeklyPremiumUser: row.weeklyPremiumUsers,
                monthlyTotalUser: row.monthlyTotalUsers,
                monthlyStandardUser: row.monthlyStandardUsers,
                monthlyPremiumUsers: row.monthlyPremiumUsers
            }
        });

        return (
            <div>

                <ReactTable
                    data={pickUpData}
                    onFetchData={this.fetchData}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    columns={[
                        {
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Pincode",
                                    minWidth: 10,
                                    id: "pincode",
                                    accessor: d => d.pincode,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["pincode"] }),
                                    filterAll: true

                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Store Name",
                                    minWidth: 10,
                                    id: "storeName",
                                    accessor: d => d.storeName,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["storeName"] }),
                                    filterAll: true

                                }
                            ]
                        },
                        {
                            Header: "Daily User",
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Total ",
                                    minWidth: 10,
                                    id: "dailyTotalUser",
                                    accessor: d => d.dailyTotalUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["dailyTotalUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Standard ",
                                    minWidth: 10,
                                    id: "dailyStandardUser",
                                    accessor: d => d.dailyStandardUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["dailyStandardUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Premium ",
                                    minWidth: 10,
                                    id: "dailyPremiumUser",
                                    accessor: d => d.dailyPremiumUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["dailyPremiumUser"] }),
                                    filterAll: true
                                }
                            ]
                        },
                        {
                            Header: "Weekly User",
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Total ",
                                    minWidth: 10,
                                    id: "weeklyTotalUser",
                                    accessor: d => d.weeklyTotalUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["weeklyTotalUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Standard ",
                                    minWidth: 10,
                                    id: "weeklyStandardUser",
                                    accessor: d => d.weeklyStandardUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["weeklyStandardUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Premium ",
                                    minWidth: 10,
                                    id: "weeklyPremiumUser",
                                    accessor: d => d.weeklyPremiumUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["weeklyPremiumUser"] }),
                                    filterAll: true
                                }
                            ]
                        },
                        {
                            Header: "Monthly User",
                            columns: [
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Total User",
                                    minWidth: 10,
                                    id: "monthlyTotalUser",
                                    accessor: d => d.monthlyTotalUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["monthlyTotalUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Standard ",
                                    minWidth: 10,
                                    id: "monthlyStandardUser",
                                    accessor: d => d.monthlyStandardUser,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["monthlyStandardUser"] }),
                                    filterAll: true
                                },
                                {
                                    headerStyle: { fontSize: '16px', height: '40px', paddingTop: '10px' },
                                    style: { textAlign: "center" },
                                    Header: "Premium",
                                    minWidth: 10,
                                    id: "monthlyPremiumUsers",
                                    accessor: d => d.monthlyPremiumUsers,
                                    filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["monthlyPremiumUsers"] }),
                                    filterAll: true
                                }
                            ]
                        },
                    ]}
                    // defaultPageSize={10}
                    className="-striped -highlight"
                    getTrProps={onRowClick}
                />
            </div>
        );
    }

}
IncomeReportByStore.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    city: state.city.city,
  });
  
  export default connect(
    mapStateToProps,
    null,
    null,
    { pure: false }
  )(withStyles(styles)(IncomeReportByStore));